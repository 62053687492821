<template>
    <div class="grid">
        <div class="col-12">
            <div class="card" v-if="bCargando">
                <div class="progress-spinner-custom">
                    <div class="spinner">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 lg:col-6 xl:col-4" v-for="(item) in oListaTipoProducto" :key="item.TipoProductoId">
            <router-link :to="'/portal/mantenedorproducto/'+item.TipoProductoId">
                <Card>
                    <template #header>
                        <div class="pt-5" style="text-align: center;">
                            <i :class="item.Icono" class="text-cyan-500" style="font-size: 5rem;"></i>
                        </div>
                    </template>
                    <template #title>
                        <div class="text-center">{{ item.Nombre }}</div>
                    </template>
                    <template #subtitle>
                        <div class="text-center">{{ item.Descripcion }}</div>
                    </template>
                </Card>
            </router-link>
        </div>
    </div>
</template>

<script setup>
import TipoProductoService from '@/service/TipoProductoService';
import Crypto from '@/utilitarios/Crypto';
import { onMounted, ref } from 'vue';

//#region Variables
const tipoProductoService = new TipoProductoService();
const oListaTipoProducto = ref(null);
const bCargando = ref(false);
//#endregion

//#region Eventos
onMounted(() => {
    inicializador();
});

//#endregion


//#region Metodos
const inicializador = () => {
    ListarTipoProducto();
}

const ListarTipoProducto = () => {
    bCargando.value = true;
    try{
        tipoProductoService.ListarTipoProducto().then(
            response => {
                const listaFiltrada = response.Data.filter(x => x.Estado);
                oListaTipoProducto.value = listaFiltrada.map(fila => ({...fila, TipoProductoId: Crypto.Encriptar(fila.TipoProductoId)}));
                bCargando.value = false;
            }
        );
    } catch (error) {
        bCargando.value = false;
        console.error('Error al Listar Tipo Producto', error);
    }
}

//#endregion
</script>
