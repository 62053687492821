<template>
    <div class="grid">
        <div class="col-12 md:col-7">
            <div class="card">
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button label="Nuevo Ingreso" icon="pi pi-plus" class="p-button-success mr-2 button-label" @click="AbrirNuevoMovimiento(1)" />
                        <Button label="Nuevo Egreso" icon="pi pi-minus" class="p-button-danger mr-2 button-label" @click="AbrirNuevoMovimiento(2)" />
                        <Button label="Actualizar" icon="pi pi-refresh" class="mr-2 button-label" severity="secondary" @click="Inicializador" />

                        <Button icon="pi pi-plus" class="p-button-success mr-2 button-icon" @click="AbrirNuevoMovimiento(1)" />
                        <Button icon="pi pi-minus" class="p-button-danger mr-2 button-icon" @click="AbrirNuevoMovimiento(2)" />
                        <Button icon="pi pi-refresh" class="mr-2 button-icon" severity="secondary" @click="Inicializador" />
                    </template>
                    <template #end>
                        <i class="pi pi-info-circle button-icon" v-tooltip.top="`Solo podrá editar o eliminar un pago el misma caja que se realizó`"></i>
                    </template>
                </Toolbar>
                <DataTable ref="dtMovimientos" 
                    v-model:expandedRows="expandedRows" 
                    :value="oCajaDiaria"
                    dataKey="MedioPagoId"
                    :rowHover="true"
                    :loading="bCargando"
                    class="p-datatable-sm"
                    v-if="cajaId != 0"
                    >

                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Movimiento Diario</h5>
                        </div>
                    </template>
                    <Column expander style="width: 5rem" />
                    <Column field="Nombre">
                        <template #body="slotProps">
                            <span style="font-size: 14pt; font-weight: bold">
                                {{ slotProps.data.Nombre }}
                            </span>
                        </template>
                    </Column>
                    <Column field="Monto">
                        <template #body="slotProps">
                            <span style="font-size: 14pt; font-weight: 400">
                                S/. {{ Utilitario.formatearDecimal(slotProps.data.ImporteTotal) }}
                            </span>
                        </template>
                    </Column>

                    <template #expansion="slotProps">
                        <div class="p-1" v-if="slotProps.data.DetalleMovimientos.length > 0">
                            <DataTable :value="slotProps.data.DetalleMovimientos">
                                <Column field="Codigo" header="Código"></Column>
                                <Column field="Fecha" header="Fecha">
                                    <template #body="slotProps">
                                        {{ Utilitario.formatoFecha(slotProps.data.Fecha, 'dd/MM/yyyy HH:mm:ss') }}
                                    </template>
                                </Column>
                                <Column field="Detalle" :header="slotProps.data.MedioPagoId < 0? 'Detalle' : 'Paciente'"></Column>
                                <Column field="Importe" header="Importe">
                                    <template #body="slotProps">
                                        S/.{{ Utilitario.formatearDecimal(slotProps.data.Importe) }}
                                    </template>
                                </Column>
                                <Column style="text-align: center;" headerStyle="min-width:12rem;">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-folder-open" v-tooltip.top="'Ver Ficha de Atención'" class="p-button-rounded mr-2 p-button-success" outlined 
                                            v-if="slotProps.data.MedioPagoId > 0" @click="AbrirFicha(slotProps.data)"  />
                                        <Button icon="pi pi-pencil" v-tooltip.top="'Editar Movimiento'" class="p-button-rounded mr-2 p-button-info" outlined 
                                            v-if="slotProps.data.MedioPagoId < 0" @click="EditarMovimiento(slotProps.data)" />
                                        <Button icon="pi pi-times" v-tooltip.top="'Eliminar Movimiento'" class="p-button-rounded mr-2 p-button-danger" outlined 
                                            v-if="slotProps.data.MedioPagoId < 0" @click="EliminarMovimiento(slotProps.data)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </template>
                </DataTable>
            </div>
        </div>
        
        <div class="col-12 md:col-5">
            <div class="card text-center">
                <h2>Saldo en Caja: <b>S/. {{ Utilitario.formatearDecimal(totalEfectivo) }}</b></h2>
                <div class="EstadoCaja">
                    <h3>Caja {{ cajaId == 0 ? 'Cerrada' : 'Aperturada' }}</h3>
                </div>
                <h5 v-if="fechaCajaAperturada">Fecha Caja: {{ Utilitario.formatoFecha(fechaCajaAperturada, 'dd/MM/yyyy HH:mm:ss') }}</h5>
                <p v-if="fechaCajaAperturada" class="mb-0 pb-0">Código Caja: <b>{{ codigoCaja }}</b></p>
                <p class="mt-0 pt-0">Usuario: <b>{{ usuarioStore.User.Usuario }}</b></p>
            </div>
        </div>
    </div>
    
    <ConfirmDialog group="headlessvalida">
        <template #container="{ message, acceptCallback }">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div class="border-circle custom-bg-warning inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                    <i class="pi pi-exclamation-triangle text-5xl"></i>
                </div>
                <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
                <p class="mb-0">{{ message.message }}</p>
                <div class="flex align-items-center gap-2 mt-4">
                    <Button label="Aceptar" class="custom-bg-warning" @click="acceptCallback"></Button>
                </div>
            </div>
        </template>
    </ConfirmDialog>

    
    <ConfirmDialog group="headless">
        <template #container="{ message, acceptCallback }">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                    <i class="pi pi-check text-5xl"></i>
                </div>
                <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
                <p class="mb-0">{{ message.message }}</p>
                <div class="flex align-items-center gap-2 mt-4">
                    <Button label="Aceptar" @click="acceptCallback"></Button>
                </div>
            </div>
        </template>
    </ConfirmDialog>
    
    <Dialog 
        v-model:visible="mostrarModal"
        :header="tipoMovimiento"
        :modal="true" class="p-fluid"
        :style="{ width: '480px' }"
    >
        <div class="p-fluid p-formgrid grid mt-5">
            <div class="field col-12 md:col-6">
                <FloatLabel>
                    <Calendar showIcon iconDisplay="input" v-model="oMovimiento.Fecha" disabled
                        inputId="dtpFechaFin" dateFormat="dd/mm/yy" showTime hourFormat="12"/>
                    <label for="lblFechaFin">Fecha</label>
                </FloatLabel>
            </div>

            <div class="field col-12 md:col-6">
                <FloatLabel>
                    <InputNumber autocomplete="off" v-model="oMovimiento.Importe"
                        inputId="locale-us" locale="en-ES" :minFractionDigits="2" />
                    <label for="lblPrecio">Importe</label>
                </FloatLabel>
            </div>

            <div class="field col-12 md:col-12">
                <FloatLabel>
                    <Textarea id="txtDiagnostico" rows="3" cols="30" v-model="oMovimiento.Detalle" />
                    <label for="lblDiagnostico">Detalle</label>
                </FloatLabel>
            </div>
        </div>        

        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text p-button-danger" @click="CerrarNuevoMovimiento"/>
            <Button label="Guardar" icon="pi pi-save" iconPos="right" class="p-button-success" @click="GuardarMovimiento" />
        </template>
    </Dialog>

</template>
<script setup>
import CajaService from '@/service/CajaService';
import MovimientoDiarioService from '@/service/MovimientoDiarioService';
import store from '@/store';
import Crypto from '@/utilitarios/Crypto';
import Utilitario from '@/utilitarios/Utilitario';
import { usePrimeVue } from 'primevue/config';
import { useConfirm } from 'primevue/useconfirm';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

//#region Variables
const confirm = useConfirm();
const router = useRouter();

const movimientoDiarioService = new MovimientoDiarioService();
const cajaService = new CajaService();

const usuarioStore = JSON.parse(store.state.datauser);
const bCargando = ref(false);
const oCajaDiaria = ref([]);
const expandedRows = ref({});
const totalEfectivo = ref(0);
const fechaCajaAperturada = ref(null);
const codigoCaja = ref('');
const cajaId = ref(0);
const mostrarModal = ref(false);
const tipoMovimiento = ref('');
const oMovimiento = ref({
    Fecha : new Date,
    Importe: 0,
    MovimientoDiarioId: 0
});
//#endregion

//#region Eventos
onMounted(() => {
     Inicializador();
     CambiarEspanol();
});
//#endregion

//#region Metodos
const Inicializador = async() => {
    bCargando.value = true;
    await ObtenerCaja();
    bCargando.value = false;
}

const CambiarEspanol = () => {
    const primevue = usePrimeVue();
    primevue.config.locale.dayNames = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    primevue.config.locale.dayNamesShort = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
    primevue.config.locale.dayNamesMin = ["D", "L", "M", "X", "J", "V", "S"];
    primevue.config.locale.firstDayOfWeek = 1;
    primevue.config.locale.monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    primevue.config.locale.monthNamesShort = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    primevue.config.locale.today = "Hoy";
    primevue.config.locale.clear = "Limpiar";
    primevue.config.locale.dateFormat = "dd/mm/yy";
    primevue.config.locale.weekHeader = "Sem";
    primevue.config.locale.pending = "Pendiente";
}

const ListarMovimientoDiario = async() => {
    const request = {
        EstablecimientoId: usuarioStore.Establecimiento.EstablecimientoId
    };

    const response = await movimientoDiarioService.ListarMovimientoDiarioService(request).then();
    if(response.Status == 200) {
        oCajaDiaria.value = response.Data;
        if(response.Data){
            const saldoInicial = response.Data.find(item => item.MedioPagoId === 0);
            const pagosEfectivo = response.Data.find(item => item.MedioPagoId === 1);
            const ingresos = response.Data.find(item => item.MedioPagoId === -1);
            const egresos = response.Data.find(item => item.MedioPagoId === -2);
            totalEfectivo.value = (Utilitario.StringIsNullOrEmpty(saldoInicial) ? 0 : saldoInicial.ImporteTotal) 
                                + (Utilitario.StringIsNullOrEmpty(pagosEfectivo) ? 0 : pagosEfectivo.ImporteTotal)
                                + (Utilitario.StringIsNullOrEmpty(ingresos) ? 0 : ingresos.ImporteTotal) 
                                + (Utilitario.StringIsNullOrEmpty(egresos) ? 0 : egresos.ImporteTotal);
        }
    }
}

const ObtenerCaja = async() => {
    const request = {
        EstablecimientoId: usuarioStore.Establecimiento.EstablecimientoId
    };
    
    const response = await cajaService.ObtenerCajaService(request).then();
    if(response.Status == 200 &&  response.Data != null) {
        const obtenerCaja = response.Data;
        fechaCajaAperturada.value = obtenerCaja.Fecha; 
        codigoCaja.value = obtenerCaja.CodigoCaja;
        cajaId.value = 1;

        if(obtenerCaja.DiaDiferente) {
            confirm.require({
                group: 'headlessvalida',
                header: 'CAJA',
                message: `Tiene aperturada la caja del día ${Utilitario.formatoFecha(fechaCajaAperturada.value, 'dd/MM/yyyy')}. \nPor favor cerrar y abrir en la fecha actual.`,
                accept: async() => {
                    console.log(`Tiene aperturada la caja del día ${Utilitario.formatoFecha(fechaCajaAperturada.value, 'dd/MM/yyyy')}`);
                    router.push({ path: `/portal/aperturacierrecaja` })
                }
            });
        }
        else{
            await ListarMovimientoDiario();
        }
    }
}

const AbrirNuevoMovimiento = (tipo) => {
    tipoMovimiento.value = 'Nuevo ' + (tipo == 1 ? 'Ingreso' : 'Egreso');
    oMovimiento.value.MovimientoDiarioId = 0;
    oMovimiento.value.Fecha = new Date;
    oMovimiento.value.Importe = 0;
    oMovimiento.value.Detalle = '';
    oMovimiento.value.TipoMovimientoDiarioId = tipo;
    mostrarModal.value = true;
}

const EditarMovimiento = (item) => {
    tipoMovimiento.value = 'Editar ' + (item.MedioPagoId == -1 ? 'Ingreso' : 'Egreso');
    oMovimiento.value.MovimientoDiarioId = item.Id;
    oMovimiento.value.Fecha = new Date(item.Fecha);
    oMovimiento.value.Importe = (item.MedioPagoId == -1 ? item.Importe : (item.Importe * -1));
    oMovimiento.value.Detalle = item.Detalle;
    oMovimiento.value.TipoMovimientoDiarioId = (item.MedioPagoId == -1 ? 1 : 2);
    mostrarModal.value = true;
}

const CerrarNuevoMovimiento = () => {
    mostrarModal.value = false;
}

const GuardarMovimiento = () => {
    try{
        const request = {
            ...oMovimiento.value,
            CajaId: usuarioStore.Caja.CajaId,
            UsuarioCreacion: usuarioStore.User.UsuarioId
        };
        
        confirm.require({
            message: `¿Está seguro de guardar el ${tipoMovimiento.value}?`,
            header: 'Confirmación',
            icon: 'pi pi-info-circle',
            rejectLabel: 'No',
            acceptLabel: 'Si',
            accept: async () => {
                bCargando.value = true;                
                const response = await movimientoDiarioService.RegistrarMovimientoDiarioService(request).then();
                if(response.Status == 201){
                    confirm.require({
                        group: 'headless',
                        header: 'Felicidades',
                        message: `Se guardó correctamente`,
                        accept: async() => {
                            mostrarModal.value = false;
                            await ListarMovimientoDiario();
                            bCargando.value = false;
                        }
                    });
                }
            }
        });
    } catch (error) {
        console.error('Error al GuardarMovimiento:', error);
        bCargando.value = false;
    }
}

const EliminarMovimiento = (item) => {
    const nombreTipo = item.MedioPagoId == -1 ? 'Ingreso': 'Egreso';
    confirm.require({
        message: `¿Está seguro de eliminar el ${nombreTipo} de ${item.Importe}?`,
        header: 'Confirmación',
        icon: 'pi pi-info-circle',
        rejectLabel: 'No',
        acceptLabel: 'Si',
        accept: async () => {
            bCargando.value = true;
             try{
                const request = {
                    MovimientoDiarioId: item.Id,
                    UsuarioCreacion: usuarioStore.User.UsuarioId
                };
                
                const response = await movimientoDiarioService.EliminarMovimientoDiarioService(request).then();
                if(response.Status == 201){
                    confirm.require({
                        group: 'headless',
                        header: 'Felicidades',
                        message: `Se eliminó correctamente`,
                        accept: async() => {
                            await ListarMovimientoDiario();
                            bCargando.value = false;
                        }
                    });
                }
            } catch (error) {
                bCargando.value = false;
                console.error('Error al EliminarMovimiento:', error);
            }
        }
    });
}

const AbrirFicha = (item) => {
    debugger;
    const pacienteIdUrl = encodeURIComponent(Crypto.Encriptar(item.PacienteId));
    const productoIdUrl = encodeURIComponent(Crypto.Encriptar(item.ProductoId));
    const fichaIdUrl = encodeURIComponent(Crypto.Encriptar(item.FichaId));
    let route = router.resolve({ path: `/portal/fichaatencion/${pacienteIdUrl}/${productoIdUrl}/${fichaIdUrl}` });
    window.open(route.href, '_blank');
}
//#endregion

</script>

<style>
.EstadoCaja {
    margin-top: 50px;
}
.EstadoCaja > * {
    text-align: center;
    background-color: black;
    color: white;
    padding: 5px;
}
</style>