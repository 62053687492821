export default class MovimientoDiarioService {
    async ListarMovimientoDiarioService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/caja/movimientodiario', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async RegistrarMovimientoDiarioService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/caja/nuevomovimientodiario', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async EliminarMovimientoDiarioService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/caja/eliminarmovimientodiario', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }
}

