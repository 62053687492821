export default class PersonaService {
    async RegistrarPersonaService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/mantenedor/nuevapersona', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async ObtenerPersonaService(id) {
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/mantenedor/persona/${id}`, {
            method: "GET"
        });
        
        return await response.json();
    }
}
