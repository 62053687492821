export default class Utilitario {
    static StringIsNullOrEmpty(campo){
        return (campo == null || campo == undefined || campo == '') ? true : false;
    }

    static validarCorreo(correo) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(correo);
    }

    static formatearFechaMes(fecha) {
        const fechaObj = new Date(fecha);
          const dia = fechaObj.getDate().toString().padStart(2, '0');
          const mes = (fechaObj.getMonth() + 1).toString().padStart(2, '0');
          const anio = fechaObj.getFullYear();
          return `${dia}/${mes}/${anio}`;
    }

    static formatoFecha(fecha, formato) {
        const fechaObj = new Date(fecha);
        let nuevaFecha = '';
        
        if(fecha != null && fecha != undefined && fecha != ''){
            if(formato == "dd/MM/yyyy"){
                const dia = fechaObj.getDate().toString().padStart(2, '0');
                const mes = (fechaObj.getUTCMonth() + 1).toString().padStart(2, '0');
                const anio = fechaObj.getUTCFullYear();
    
                nuevaFecha = `${dia}/${mes}/${anio}`;
            }
            if(formato == "yyyy-MM-dd"){
                const dia = fechaObj.getDate().toString().padStart(2, '0');
                const mes = (fechaObj.getMonth() + 1).toString().padStart(2, '0');
                const anio = fechaObj.getFullYear();
    
                nuevaFecha = `${anio}-${mes}-${dia}`;
            }
            
            if(formato == "yyyy-MM-dd HH:mm:ss"){
                const dia = fechaObj.getDate().toString().padStart(2, '0');
                const mes = (fechaObj.getMonth() + 1).toString().padStart(2, '0');
                const anio = fechaObj.getFullYear();
                const hora = fechaObj.getHours().toString().padStart(2, '0');
                const minuto = fechaObj.getMinutes().toString().padStart(2, '0');
                const segundo = fechaObj.getSeconds().toString().padStart(2, '0');
                
                nuevaFecha = `${anio}-${mes}-${dia} ${hora}:${minuto}:${segundo}`;
            }
            
            if(formato == "dd/MM/yyyy HH:mm:ss"){
                const dia = fechaObj.getDate().toString().padStart(2, '0');
                const mes = (fechaObj.getMonth() + 1).toString().padStart(2, '0');
                const anio = fechaObj.getFullYear();
                const hora = fechaObj.getHours().toString().padStart(2, '0');
                const minuto = fechaObj.getMinutes().toString().padStart(2, '0');
                const segundo = fechaObj.getSeconds().toString().padStart(2, '0');
                
                nuevaFecha = `${dia}/${mes}/${anio} ${hora}:${minuto}:${segundo}`;
            }
        }        

        return nuevaFecha;
    }

    static formatoDate(fechaFormato) {
        let nuevaFecha = null;
        if(fechaFormato != "" && fechaFormato != null && fechaFormato != undefined){
            nuevaFecha = new Date(fechaFormato);
        }
        return nuevaFecha;
    }

    static formatoDateTime(fechaFormato) {
        let nuevaFecha = null;
        if(fechaFormato != "" && fechaFormato != null && fechaFormato != undefined){
            nuevaFecha = new Date(fechaFormato);
        }
        return nuevaFecha;
    }

    static formatearDecimal(valor) {
        if (typeof valor !== 'number') {
            return valor;
        }
        return valor.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    static obtenerExtensionArchivo(nombreArchivo) {
        return nombreArchivo.slice((nombreArchivo.lastIndexOf('.') - 1 >>> 0) + 2);
    }
}