<template>
    <div class="grid">
        <div class="col-12">
            <div class="card p-2 mb-2">
                <Toolbar class="p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button icon="pi pi-user" severity="info" rounded outlined aria-label="User" />
                        <p><b class="ml-2">Ficha de Atención: </b> {{ oFicha.NombrePaciente }} - {{ oFicha.NumeroDocumento }}</p>
                    </template>

                    <template #end>
                        <Button label="Atrás" icon="pi pi-arrow-left" severity="secondary" @click="IrAtras"></Button>
                    </template>
                </Toolbar>
            </div>

            <div class="card">
                <div v-if="bCargando" class="progress-spinner-custom">
                    <div class="spinner">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <div class="flex flex-column h-500rem">
                    <h5>{{ oCita.CodigoCita != '' ? 'Cita #' + oCita.CodigoCita : 'Nueva Cita' }}</h5>
                    <div class="border-2 border-dashed surface-border border-round surface-ground justify-content-center align-items-center font-medium">
                        <div class="p-fluid p-formgrid grid m-5 mb-0 mt-0">
                            <div class="field col-12 md:col-9 pb-0 mb-0">
                                <p><b class="ml-2">Paquete: </b> {{ oFicha.NombreProducto }} <Tag severity="info">{{ oFicha.NombreCategoria }}</Tag></p>
                            </div>
                            <div class="field col-12 md:col-3 text-right font-bold pb-0 mb-0">
                                <p>Atención #: {{ oFicha.CodigoFicha }}</p>
                            </div>

                            <Divider layout="horizontal" class="pt-1 mt-1 mb-5" />

                            <div class="field col-12 md:col-4 mb-0">
                                <FloatLabel>
                                    <Calendar showIcon iconDisplay="input" v-model="oCita.FechaInicio"
                                        inputId="dtpFechaInicio" dateFormat="dd/mm/yy" showTime hourFormat="12"
                                        :class="{ 'p-invalid' : fechaInicioValido }" :disabled="bFechaInicioDisabled" />
                                    <label for="lblFechaInicio">Fecha Inicio</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-4 mb-0">
                                <FloatLabel>
                                    <Dropdown v-model="oCita.PersonalId" :options="oListaFisioterapeuta"
                                        optionLabel="NombreCompleto" optionValue="PersonalId" class="w-full"  
                                        :class="{ 'p-invalid' : fisioterapeutaInvalido }" :disabled="bFisioterapeutaDisabled"/>
                                    <label for="lblFisioterapeuta">Fisioterapeuta</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-4 mb-0">
                                <FloatLabel>
                                    <Dropdown v-model="oCita.EstadoCitaId" :options="oListaEstadoCita"
                                        optionLabel="Nombre" optionValue="Codigo" class="w-full" disabled />
                                    <label for="lblFisioterapeuta">Estado Cita</label>
                                </FloatLabel>
                            </div>
                            <div v-if="oListaProductoDetalle.length > 0" class="field col-12 md:col-12 mb-0 pb-0 pt-0">
                                <p><b class="ml-2">Tratamientos</b></p>
                            </div>
                            <div v-if="oListaProductoDetalle.length > 0" class="field col-12 md:col-12">
                                <div class="card p-2">
                                    <DataView :value="oListaProductoDetalle" :class="{ 'p-invalid' : fisioterapeutaInvalido }">
                                        <template #list="slotProps">
                                            <div class="grid grid-nogutter">
                                                <div v-for="(item, index) in slotProps.items" :key="index" class="col-12 sm:col-4 md:col-4 xl:col-4 p-1" >
                                                    <div class="ml-2 p-0 border-round flex flex-column">
                                                        <div class="flex flex-column md:flex-row justify-content-between md:align-items-center flex-1 gap-4">
                                                            <div class="flex flex-column md:align-items-end gap-5">
                                                                <div class="flex flex-row-reverse md:flex-row gap-2" v-tooltip.top = "'['+ item.Codigo + '] ' + (item.Descripcion ? item.Descripcion : '')">
                                                                    <Checkbox v-model="item.ValorCheck" :binary="true" :disabled="bTratamientosDisabled" /> {{ item.Nombre }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </DataView>
                                </div>
                            </div>
                            <div class="field col-12 md:col-12">
                                <FloatLabel>
                                    <Textarea id="txtNota" rows="3" cols="30" v-model="oCita.Nota" :disabled="bNotaDisabled" />
                                    <label for="lblNota">Nota</label>
                                </FloatLabel>
                            </div>
                            <div v-if="bCrearCita || bIniciarCita" class="field col-12 md:col-4"></div>
                            <div v-if="bCrearCita || bIniciarCita" class="field col-12 md:col-4"></div>
                            <div v-if="bCrearCita" class="field col-12 md:col-4">
                                <Button label="Crear Cita" icon="pi pi-plus" severity="primary" iconPos="right" @click="CrearCita()" :loading="bCargando" outlined />
                            </div>
                            <div v-if="bIniciarCita" class="field col-12 md:col-4">
                                <Button label="Iniciar Cita" icon="pi pi-caret-right" severity="Info" @click="IniciarCita" :disabled="oCita.EstadoCitaId != 1"></Button>
                            </div>
                        </div>

                        <div v-if="bMostrarFichaTecnica" class="p-fluid p-formgrid grid m-5 mt-0">
                            <Divider layout="horizontal" />
                            <div class="field col-12 md:col-12 mb-0 pb-0 pt-0">
                                <p><b class="ml-2">Ficha Técnica</b></p>
                            </div>

                            <div class="field col-12 md:col-12">
                                <div class="card p-2">
                                    <DataView :value="oListaParametroFichaTecnica" >
                                        <template #list="slotProps">
                                            <div class="grid grid-nogutter">
                                                <div v-for="(item, index) in slotProps.items" :key="index" class="col-12 sm:col-6 md:col-4 xl:col-6 p-1" :class="item.EsResaltante ? 'EsResaltante' : ''">
                                                    <div class="ml-2 p-0 border-round flex flex-column">
                                                        <div class="flex flex-column md:flex-row justify-content-between md:align-items-center flex-1 gap-4">
                                                            <div class="flex flex-row md:flex-column justify-content-between align-items-start gap-2">
                                                                {{ item.Nombre }}
                                                            </div>
                                                            <div class="flex flex-column md:align-items-end gap-5">
                                                                <div class="flex flex-row-reverse md:flex-row gap-2">
                                                                    <template v-if="item.RequiereCheck">
                                                                        <Checkbox v-model="item.ValorCheck" :binary="true" :disabled="bFichaTecnicaDisabled" />
                                                                    </template>
                                                                    <template v-if="item.RequiereComentario">
                                                                        <Textarea v-model="item.Comentario" :rows="item.NumeroFilas" cols="30" :disabled="bFichaTecnicaDisabled" />
                                                                    </template>
                                                                    <template v-if="item.RequiereNumero">
                                                                        <InputNumber autocomplete="off" v-model="item.ValorCantidad" inputId="locale-us" locale="en-ES" :minFractionDigits="2" :disabled="bFichaTecnicaDisabled" />
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </DataView>
                                </div>
                            </div>
                            <div class="field col-12 md:col-12">
                                <FloatLabel>
                                    <Textarea id="txtNotaFicha" rows="3" cols="30" v-model="oCita.NotaFicha" :disabled="bNotaFichaDisabled" />
                                    <label for="lblNotaFicha">Nota Ficha</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-12 mb-0 pb-0 pt-0">
                                <p><b class="ml-2">Fotos</b>(Antes del tratamiento)</p>
                            </div>
                            <Galleria v-model:activeIndex="activarIndice" v-model:visible="mostrarPersonalizado" :value="oListaFotosAntes" :responsiveOptions="responsiveOptions" :numVisible="7"
                                containerStyle="max-width: 850px" :circular="true" :fullScreen="true" :showItemNavigators="true" :showThumbnails="false">
                                <template #item="slotProps">
                                    <img :src="slotProps.item.UrlPublica" :alt="slotProps.item.NombreOriginal" style="width: 100%; display: block" />
                                </template>
                                <template #thumbnail="slotProps">
                                    <img :src="slotProps.item.UrlPublica" :alt="slotProps.item.NombreOriginal" style="display: block" />
                                </template>
                            </Galleria>

                            <div class="col-12 lg:col-6 xl:col-3" v-for="(item, index) in oListaFotosAntes" :key="index">
                                <Card>
                                    <template #header>
                                        <Button icon="pi pi-times" severity="danger" v-tooltip.top="'Eliminar Imagen'"  
                                            text rounded aria-label="Cancel" @click="EliminarImagen(item, 1)" :disabled="bEliminarFotoDisabled" />
                                        <div style="text-align: center;">
                                            <img :src="item.UrlPublica" :alt="item.NombreOriginal" 
                                            style="cursor: pointer; max-width: 100%; height: 100px; padding-top: 5px;" 
                                            @click="VerImagenCompleta(index)" />
                                        </div>
                                    </template>
                                    <template #subtitle>
                                        <div class="text-center" style="font-size: 9.5pt">{{ item.NombreOriginal }} </div>
                                        <p class="text-center mb-0 mt-1" style="font-size: 8pt;color: #9e9e9e;">{{ item.TipoImagen + ' | ' + (item.TamanioBytes/1024).toFixed(2) + 'KB' }}</p>
                                        <p class="text-center" style="font-size: 8pt;color: #9e9e9e;">{{ item.FechaCreacionImagen }}</p>
                                    </template>
                                </Card>
                            </div>

                            <div v-if="estadoFotosAntes && !bEliminarFotoDisabled" class="field col-12 md:col-12">
                                <div class="card">
                                    <FileUpload 
                                        name="filename[]" 
                                        @uploader="SubirArchivos" 
                                        :multiple="true" 
                                        accept="image/*" 
                                        :maxFileSize="1000000"
                                        chooseLabel="Buscar Archivos"
                                        uploadLabel="Subir"
                                        cancelLabel="Cancelar"
                                        :fileLimit="ObtenerLimiteComputado"
                                        invalidFileLimitMessage="Se superó el número máximo de archivos; el límite es 4 como máximo"
                                        customUpload
                                    >
                                        <template #empty>
                                            <p>Arrastre y suelte archivos aquí para cargarlos.</p>
                                        </template>
                                    </FileUpload>
                                </div>
                            </div>
                            <div  v-if="bGuardarCambios" class="field col-12 md:col-4"></div>
                            <div  v-if="bGuardarCambios" class="field col-12 md:col-4"></div>
                            <div  v-if="bGuardarCambios" class="field col-12 md:col-4">
                                <Button label="Guardar Cambios" icon="pi pi-save" severity="secondary" iconPos="right" @click="GuardarCambios()" :loading="bCargando" outlined />
                            </div>
                            <Divider layout="horizontal" />
                            <div class="field col-12 md:col-12 mb-0 pb-5 pt-0">
                                <p><b class="ml-2">Finalizar Cita</b></p>
                            </div>
                            <div class="field col-12 md:col-12 mb-0 pb-0 pt-0">
                                <p><b class="ml-2">Fotos</b>(Después del tratamiento)</p>
                            </div>
                            <Galleria v-model:activeIndex="activarIndice" v-model:visible="mostrarPersonalizado" :value="oListaFotosDespues" :responsiveOptions="responsiveOptions" :numVisible="7"
                                containerStyle="max-width: 850px" :circular="true" :fullScreen="true" :showItemNavigators="true" :showThumbnails="false">
                                <template #item="slotProps">
                                    <img :src="slotProps.item.UrlPublica" :alt="slotProps.item.NombreOriginal" style="width: 100%; display: block" />
                                </template>
                                <template #thumbnail="slotProps">
                                    <img :src="slotProps.item.UrlPublica" :alt="slotProps.item.NombreOriginal" style="display: block" />
                                </template>
                            </Galleria>

                            <div class="col-12 lg:col-6 xl:col-3" v-for="(item, index) in oListaFotosDespues" :key="index">
                                <Card>
                                    <template #header>
                                        <Button icon="pi pi-times" severity="danger" v-tooltip.top="'Eliminar Imagen'"  
                                            text rounded aria-label="Cancel" @click="EliminarImagen(item, 2)" :disabled="bEliminarFotoDisabled" />
                                        <div style="text-align: center;">
                                            <img :src="item.UrlPublica" :alt="item.NombreOriginal" 
                                            style="cursor: pointer; max-width: 100%; height: 100px; padding-top: 5px;" 
                                            @click="VerImagenCompleta(index)" />
                                        </div>
                                    </template>
                                    <template #subtitle>
                                        <div class="text-center" style="font-size: 9.5pt">{{ item.NombreOriginal }} </div>
                                        <p class="text-center mb-0 mt-1" style="font-size: 8pt;color: #9e9e9e;">{{ item.TipoImagen + ' | ' + (item.TamanioBytes/1024).toFixed(2) + 'KB' }}</p>
                                        <p class="text-center" style="font-size: 8pt;color: #9e9e9e;">{{ item.FechaCreacionImagen }}</p>
                                    </template>
                                </Card>
                            </div>

                            <div v-if="estadoFotosDespues && !bEliminarFotoDisabled" class="field col-12 md:col-12">
                                <div class="card">
                                    <FileUpload 
                                        name="filename[]" 
                                        @uploader="SubirArchivosDespues" 
                                        :multiple="true" 
                                        accept="image/*" 
                                        :maxFileSize="1000000"
                                        chooseLabel="Buscar Archivos"
                                        uploadLabel="Subir"
                                        cancelLabel="Cancelar"
                                        :fileLimit="limiteFotosDespues"
                                        invalidFileLimitMessage="Se superó el número máximo de archivos; el límite es 4 como máximo"
                                        customUpload
                                    >
                                        <template #empty>
                                            <p>Arrastre y suelte archivos aquí para cargarlos.</p>
                                        </template>
                                    </FileUpload>
                                </div>
                            </div>
                            <div class="field col-12 md:col-12 pb-0 mb-0"></div>
                            <div class="field col-12 md:col-4 mt-5 pb-0 mb-0">
                                <FloatLabel>
                                    <Calendar showIcon iconDisplay="input" v-model="oCita.FechaFin"
                                        inputId="dtpFechaFin" dateFormat="dd/mm/yy" showTime hourFormat="12"
                                        :class="{ 'p-invalid' : fechaFinValido }" :disabled="bFechaFinDisabled" />
                                    <label for="lblFechaFin">Fecha Fin</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-12">
                                <small> Una vez ingresada la Fecha Fin no podrá modificar la cita.</small>
                            </div>
                        </div>

                    </div>

                    <div v-if="oCita.FechaFin" class="flex mt-4 justify-content-end">
                        <Button label="Finalizar Cita" icon="pi pi-check" iconPos="right" @click="FinalizarCita()" :loading="bCargando"  :disabled="oCita.EstadoCitaId == 3" />
                    </div>

                </div>
            </div>
        </div>
    </div>

    <ConfirmDialog group="headless">
        <template #container="{ message, acceptCallback }">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                    <i class="pi pi-check text-5xl"></i>
                </div>
                <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
                <p class="mb-0">{{ message.message }}</p>
                <div class="flex align-items-center gap-2 mt-4">
                    <Button label="Aceptar" @click="acceptCallback"></Button>
                </div>
            </div>
        </template>
    </ConfirmDialog>

</template>

<script setup>
import ArchivoService from '@/service/ArchivoService';
import CitaAtencionService from '@/service/CitaAtencionService';
import ConstanteService from '@/service/ConstanteService';
import FichaAtencionService from '@/service/FichaAtencion';
import ParametroFichaTecnicaService from '@/service/ParametroFichaTecnicaService';
import PersonalService from '@/service/PersonalService';
import Crypto from '@/utilitarios/Crypto';
import Utilitario from '@/utilitarios/Utilitario';
import { usePrimeVue } from 'primevue/config';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

//#region Variables
const route = useRoute();
const store = useStore();
const router = useRouter();
const toast = useToast();
const confirm = useConfirm();

const fichaAtencionService = new FichaAtencionService();
const personalService = new PersonalService();
const parametroFichaTecnicaService = new ParametroFichaTecnicaService();
const citaAtencionService = new CitaAtencionService();
const constanteService = new ConstanteService();
const archivosService = new ArchivoService();

const usuarioStore = JSON.parse(store.state.datauser);
const bCargando = ref(false);
const oListaFisioterapeuta = ref([]);
const oListaParametroFichaTecnica = ref([]);
const oListaEstadoCita = ref([]);
const oListaProductoDetalle = ref([]);
const oListaFotosAntes = ref([]);
const oListaFotosDespues = ref([]);

const fichaId = ref('');
const citaId = ref('');
const idPaciente = ref(0);
const idProducto = ref(0);
const fechaInicioValido = ref(false);
const fisioterapeutaInvalido = ref(false);
const fechaFinValido = ref(false);
const bCrearCita = ref(false);
const bIniciarCita = ref(false);
const bGuardarCambios = ref(false);
const bFinalizarCita = ref(false);
const bMostrarFichaTecnica = ref(false);
const bFechaInicioDisabled = ref(false);
const bFisioterapeutaDisabled = ref(false);
const bTratamientosDisabled = ref(false);
const bNotaDisabled = ref(false);
const bFichaTecnicaDisabled = ref(false);
const bNotaFichaDisabled = ref(false);
const bEliminarFotoDisabled = ref(false);
const bFechaFinDisabled = ref(false);
const oFicha = ref({});
const oCita = ref({
    EstadoCitaId: 1,
    CodigoCita: '',
    FechaInicio: new Date,
});
const estadoFotosAntes = ref(false);
const limiteFotosAntes = ref(0);
const estadoFotosDespues = ref(false);
const limiteFotosDespues = ref(0);
const activarIndice = ref(0);
const mostrarPersonalizado = ref(false);
const responsiveOptions = ref([
    {
        breakpoint: '1024px',
        numVisible: 5
    },
    {
        breakpoint: '768px',
        numVisible: 3
    },
    {
        breakpoint: '560px',
        numVisible: 1
    }
]);

//#endregion

//#region Eventos
onMounted(() => {
     inicializador();
     CambiarEspanol();
});
//#endregion

//#region Metodos
const inicializador = async() => {
    bCargando.value = true;

    bCrearCita.value = true;
    fichaId.value = Crypto.Desencriptar(route.params.fichaid);
    citaId.value  = Crypto.Desencriptar(route.params.citaid);
    await ObtenerFicha(fichaId.value);
    ListarPersonal();
    ListarEstadoCita();
    limiteFotosAntes.value = 4;
    limiteFotosDespues.value = 4;
    if(!Utilitario.StringIsNullOrEmpty(citaId.value)){
        bCrearCita.value = false;
        bIniciarCita.value = true;
        await ObtenerCitaAtencion();
    }
    bCargando.value = false;
}

const CambiarEspanol = () => {
    const primevue = usePrimeVue();
    primevue.config.locale.dayNames = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    primevue.config.locale.dayNamesShort = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
    primevue.config.locale.dayNamesMin = ["D", "L", "M", "X", "J", "V", "S"];
    primevue.config.locale.firstDayOfWeek = 1;
    primevue.config.locale.monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    primevue.config.locale.monthNamesShort = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    primevue.config.locale.today = "Hoy";
    primevue.config.locale.clear = "Limpiar";
    primevue.config.locale.dateFormat = "dd/mm/yy";
    primevue.config.locale.weekHeader = "Sem";
    primevue.config.locale.pending = "Pendiente";
}

const IrAtras = () => {
    router.push({ path: `/portal/fichaatencion/${encodeURIComponent(Crypto.Encriptar(idPaciente.value))}/${encodeURIComponent(Crypto.Encriptar(idProducto.value))}/${encodeURIComponent(route.params.fichaid)}` });
}

const ObtenerFicha = async(idParametro) => {
    try{
        const request = {
            Id: idParametro
        };
        
        const response = await fichaAtencionService.ObtenerFichaService(request).then();
        if(response.Status == 200){
            const obtenerFicha = response.Data;
            oFicha.value.FichaAtencionId = obtenerFicha.FichaAtencionId;
            oFicha.value.CodigoFicha = obtenerFicha.CodigoFichaAtencion;
            oFicha.value.NombrePaciente = obtenerFicha.NombrePaciente;
            oFicha.value.NumeroDocumento = obtenerFicha.NumeroDocumento;
            oFicha.value.NombreProducto = obtenerFicha.NombreProducto;
            oFicha.value.CategoriaId = obtenerFicha.CategoriaId;
            oFicha.value.NombreCategoria = obtenerFicha.NombreCategoria;
            oListaProductoDetalle.value = obtenerFicha.ListaProductoDetalle.map(fila => ({ ...fila, ValorCheck: false }));

            idPaciente.value = obtenerFicha.PacienteId;
            idProducto.value = obtenerFicha.ProductoId;

            await ListarparametroFichaTecnica(oFicha.value.CategoriaId);
        }
    } catch (error) {
        console.error('Error al ObtenerFicha:', error);
    }
}

const ListarPersonal = async() => {
    try{
        const request = {
            TipoPersonalId : 4
        };
        
        const response = await personalService.ListarPersonalService(request).then();
        if(response.Status == 200){
            oListaFisioterapeuta.value = response.Data.map(fila => ({ ...fila, NombreCompleto : fila.Persona.NombreCompleto }));
        }
    }
    catch (error) {
        console.error('Error al ListarPersonal:', error);
    }
}

const ListarparametroFichaTecnica = async(categoriaId) => {
    try{
        const response = await parametroFichaTecnicaService.ListarParametroFichaTecnicaService(categoriaId).then();
        if(response.Status == 200){
            const oListaData = response.Data.map(fila => ({ ...fila, ValorCheck: false, Comentario: '', ValorCantidad: 0 }));
            oListaParametroFichaTecnica.value = oListaData;
        }
    }
    catch (error) {
        console.error('Error al ListarPersonal:', error);
    } 
}

const ListarEstadoCita = async() => {
    try{
        constanteService.ListarEstadoCitaAtencionService().then(
            response => {
                oListaEstadoCita.value = response;
            }
        );
    }
    catch (error) {
        console.error('Error al ListarEstadoCita:', error);
    }
}

const CrearCita = () => {
    const valida = ref(false);
    const { ...model } = oCita.value;
    if(!model?.FechaInicio) {
        fechaInicioValido.value = true;
        valida.value = true;
    }
    else {
        fechaInicioValido.value = false;
    }

    if(!model?.PersonalId) {
        fisioterapeutaInvalido.value = true;
        valida.value = true;
    }
    else {
        fisioterapeutaInvalido.value = false;
    }

    if(oListaProductoDetalle.value.length > 0){
        const marcados = oListaProductoDetalle.value.filter(objeto => objeto.ValorCheck ).length;
        if(marcados == 0) {
            toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor seleccione al menos un tratamiento.', life: 9000 });
            valida.value = true;
        }
    }

    if(valida.value){
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
        return;
    }

    RegistrarCitaAtencion(0);
}

const IniciarCita = () => {
    const valida = ref(false);
    const { ...model } = oCita.value;
    if(!model?.FechaInicio) {
        fechaInicioValido.value = true;
        valida.value = true;
    }
    else {
        fechaInicioValido.value = false;
    }

    if(!model?.PersonalId) {
        fisioterapeutaInvalido.value = true;
        valida.value = true;
    }
    else {
        fisioterapeutaInvalido.value = false;
    }

    if(valida.value){
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
        return;
    }

    oCita.value.EstadoCitaId = 2;
    RegistrarCitaAtencion(1);
}

const RegistrarCitaAtencion = async(estadoCita) => {
    try{
        bCargando.value = true;
        const { ...model } = oCita.value;

        oListaParametroFichaTecnica.value.forEach(objeto => {
            if(objeto.RequiereNumero){
                objeto.ValorCheck = objeto.ValorCantidad > 0 ? true : false;
            }
        });

        const request = {
            ...model,
            FichaAtencionId: fichaId.value,
            ListaTratamientos: oListaProductoDetalle.value.filter(objeto => objeto.ValorCheck ),
            ListaParametroFichaTecnica: oListaParametroFichaTecnica.value.filter(x => x.ValorCheck),
            UsuarioCreacion: usuarioStore.User.UsuarioId
        }
        
        const mensajePregunta = ref('');
        const mensajeConfirmacion = ref('');
        if(estadoCita == 0) {
            mensajePregunta.value = 'crear';
            mensajeConfirmacion.value = 'creó';
        }
        if(estadoCita == 1) {
            mensajePregunta.value = 'iniciar';
            mensajeConfirmacion.value = 'inició';
        }
        if(estadoCita == 2) {
            mensajePregunta.value = 'guardar los cambios de ';
            mensajeConfirmacion.value = 'guardaron los cambios de ';
        }
        if(estadoCita == 3) {
            mensajePregunta.value = 'finalizar ';
            mensajeConfirmacion.value = 'finalizó ';
        }

        confirm.require({
            message: `¿Está seguro de ${mensajePregunta.value} la cita?`,
            header: 'Confirmación',
            icon: 'pi pi-info-circle',
            rejectLabel: 'No',
            acceptLabel: 'Si',
            accept: async () => {
                try{
                    bCargando.value = true;
                    const response = await citaAtencionService.RegistrarCitaAtencionService(request).then();
                    if(response.Status == 201){
                        const id = encodeURIComponent(route.params.fichaid);
                        const citaIdUrl = encodeURIComponent(Crypto.Encriptar(response.Data[0].CitaAtencionId));
                        citaId.value = response.Data[0].CitaAtencionId;
                        const codigo = response.Data[0].CodigoCitaAtencion;
                        confirm.require({
                            group: 'headless',
                            header: 'Felicidades',
                            message: `Se ${mensajeConfirmacion.value} la cita #${codigo} correctamente`,
                            accept: () => {
                                bCargando.value = false;
                                router.push({ path: `/portal/citaatencion/${id}/${citaIdUrl}` });
                                ObtenerCitaAtencion();
                            }
                        });
                    }
                }
                catch (error) {
                    bCargando.value = false;
                    console.error('Error al Inicar Cita:', error);
                }
            }
        });
        
    }
    catch (error) {
        console.error('Error al ListarPersonal:', error);
    } finally {
        bCargando.value = false;
    }
}

const ObtenerCitaAtencion = async() => {
    try{
        const request = {
            CitaAtencionId: citaId.value
        };

        const response = await citaAtencionService.ListarCitaAtencionService(request).then();
        if(response.Status == 200){
            const obtenerCita = response.Data[0];
            oCita.value.CitaAtencionId = obtenerCita.CitaAtencionId;
            oCita.value.CodigoCita = obtenerCita.CodigoCitaAtencion;
            oCita.value.FechaInicio = Utilitario.formatoDateTime(obtenerCita.FechaInicio);
            oCita.value.FechaFin = Utilitario.formatoDateTime(obtenerCita.FechaFin);
            oCita.value.PersonalId = obtenerCita.PersonalId;
            oCita.value.EstadoCitaId = obtenerCita.EstadoCitaId;
            oCita.value.Nota = obtenerCita.Nota;
            oCita.value.NotaFicha = obtenerCita.NotaFicha;

            switch (obtenerCita.EstadoCitaId) {
                case 1:
                    bCrearCita.value = false;
                    bIniciarCita.value = true;
                    bGuardarCambios.value = false;
                    bMostrarFichaTecnica.value = false;
                    bFinalizarCita.value = false;

                    bFechaInicioDisabled.value = false;
                    bFisioterapeutaDisabled.value = false;
                    bTratamientosDisabled.value = false;
                    bNotaDisabled.value = false;
                    bFichaTecnicaDisabled.value = false;
                    bNotaFichaDisabled.value = false;
                    bEliminarFotoDisabled.value = false;
                    bFechaFinDisabled.value = false;

                    break;
                case 2:
                    bCrearCita.value = false;
                    bIniciarCita.value = false;
                    bGuardarCambios.value = true;
                    bMostrarFichaTecnica.value = true;
                    bFinalizarCita.value = true;

                    bFechaInicioDisabled.value = true;
                    bFisioterapeutaDisabled.value = true;
                    bTratamientosDisabled.value = true;
                    bNotaDisabled.value = true;
                    bFichaTecnicaDisabled.value = false;
                    bNotaFichaDisabled.value = false;
                    bEliminarFotoDisabled.value = false;
                    bFechaFinDisabled.value = false;

                    break;
                case 3:
                    bCrearCita.value = false;
                    bIniciarCita.value = false;
                    bGuardarCambios.value = false;
                    bMostrarFichaTecnica.value = true;
                    bFinalizarCita.value = false;

                    bFechaInicioDisabled.value = true;
                    bFisioterapeutaDisabled.value = true;
                    bTratamientosDisabled.value = true;
                    bNotaDisabled.value = true;
                    bFichaTecnicaDisabled.value = true;
                    bNotaFichaDisabled.value = true;
                    bEliminarFotoDisabled.value = true;
                    bFechaFinDisabled.value = true;

                    break;
                default:
                    break;
            }

            oListaProductoDetalle.value.forEach((item, index) => {
                const itemBD = obtenerCita.ListaTratamientos.find(condicion => condicion.ProductoId === item.ProductoId);
                if(itemBD) {
                    oListaProductoDetalle.value[index].ValorCheck = itemBD.ValorCheck;
                }
            });
            
            oListaParametroFichaTecnica.value.forEach((item, index) => {
                const itemBD = obtenerCita.ListaFichaTecnica.find(condicion => condicion.ParametroFichaTecnicaId === item.ParametroFichaTecnicaId);
                if(itemBD) {
                    oListaParametroFichaTecnica.value[index].ValorCheck = itemBD.ValorCheck;
                    oListaParametroFichaTecnica.value[index].Comentario = itemBD.Comentario;
                    oListaParametroFichaTecnica.value[index].ValorCantidad = itemBD.ValorCantidad;
                }
            });

            await ListarCitaFotos(obtenerCita.CitaAtencionId, 1);
            await ListarCitaFotos(obtenerCita.CitaAtencionId, 2);
        }
    }
    catch (error) {
        console.error('Error al ObtenerCitaAtencion:', error);
    }
}

const GuardarCambios = async() => {
    try{
        oCita.value.FechaFin = '';7
        RegistrarCitaAtencion(2);
    }
    catch (error) {
        console.error('Error al GuardarCambios:', error);
    } finally {
        bCargando.value = false;
    }
}

const FinalizarCita = async() => {
    try{
        const valida = ref(false);
        const { ...model } = oCita.value;
        if(!model?.FechaFin) {
            fechaFinValido.value = true;
            valida.value = true;
        }
        else {
            fechaFinValido.value = false;
        }

        if(valida.value){
            toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
            return;
        }

        oCita.value.EstadoCitaId = 3;
        RegistrarCitaAtencion(3);
    }
    catch (error) {
        console.error('Error al FinalizarCita:', error);
    } finally {
        bCargando.value = false;
    }
}

const SubirArchivos = async(event) => {
    try {
        let formData = new FormData();
        const oListaFotosSubir = ref([]);
        event.files.forEach((file, index) => {
            const extension = Utilitario.obtenerExtensionArchivo(file.name);
            const nombreArchivo = oCita.value.CodigoCita + '_' + (index + 1) + '.' + extension;
            formData.append('nombrearchivo', new Blob([file], { type: file.type }), nombreArchivo);

            oListaFotosSubir.value.push({
                NombreOriginal: file.name,
                NombreImagen: nombreArchivo,
                TipoFotoId: 1
            })
        });
        
        const response = await archivosService.SubirArchivosService(formData);
        if(response.Status == 200){
            const oListaNuevaFotosAntes = ref([]);
            oListaNuevaFotosAntes.value = response.Data.map(fila => 
                {
                    const filtrarFoto = oListaFotosSubir.value.find(foto => foto.NombreImagen == fila.NombreOriginal);   
                    return {
                        ...fila,
                        NombreOriginal: filtrarFoto.NombreOriginal,
                        TipoFotoId: filtrarFoto.TipoFotoId
                    }
                }
            );
            toast.add({ severity: 'success', summary: 'Success', detail: 'Los archivos se subieron correctamente', life: 3000 });

            oListaNuevaFotosAntes.value.push(...oListaFotosAntes.value);
            RegistrarCitaFotos(oListaNuevaFotosAntes.value, 1);
        }
        
    }
    catch (error) {
        console.error('Error al subir archivos:', error);
    }
};

const SubirArchivosDespues = async(event) => {
    try {
        let formData = new FormData();
        const oListaFotosSubir = ref([]);
        event.files.forEach((file, index) => {
            const extension = Utilitario.obtenerExtensionArchivo(file.name);
            const nombreArchivo = oCita.value.CodigoCita + '_' + (index + 1) + '.' + extension;
            formData.append('nombrearchivo', new Blob([file], { type: file.type }), nombreArchivo);

            oListaFotosSubir.value.push({
                NombreOriginal: file.name,
                NombreImagen: nombreArchivo,
                TipoFotoId: 2
            })
        });
        
        const response = await archivosService.SubirArchivosDespuesService(formData);
        if(response.Status == 200){
            const oListaNuevaFotosDespues = ref([]);
            oListaNuevaFotosDespues.value = response.Data.map(fila => 
                {
                    const filtrarFoto = oListaFotosSubir.value.find(foto => foto.NombreImagen == fila.NombreOriginal);   
                    return {
                        ...fila,
                        NombreOriginal: filtrarFoto.NombreOriginal,
                        TipoFotoId: filtrarFoto.TipoFotoId
                    }
                }
            );
            toast.add({ severity: 'success', summary: 'Success', detail: 'Los archivos se subieron correctamente', life: 3000 });

            oListaNuevaFotosDespues.value.push(...oListaFotosDespues.value);
            RegistrarCitaFotos(oListaNuevaFotosDespues.value, 2);
        }
        
    }
    catch (error) {
        console.error('Error al subir archivos:', error);
    }
};

const RegistrarCitaFotos = async(oLista, tipo) => {
    try{
        bCargando.value = true;
        const request = {
            CitaAtencionId: citaId.value,
            ListaFotos: oLista,
            UsuarioCreacion: usuarioStore.User.UsuarioId
        }

        const response = await citaAtencionService.RegistrarCitaAtencionFotosService(request).then();
        if(response.Status == 201){
            oListaFotosAntes.value = response.Data.filter(fila => fila.TipoFotoId == 1);
            oListaFotosDespues.value = response.Data.filter(fila => fila.TipoFotoId == 2);
            confirm.require({
                group: 'headless',
                header: 'Felicidades',
                message: `Se subieron los archivos correctamente`,
                accept: () => {
                    if(tipo == 1) {
                        limiteFotosAntes.value = (4 - oListaFotosAntes.value.length);
                        estadoFotosAntes.value = limiteFotosAntes.value > 0 ? true : false;
                    }
                    else if(tipo == 2) {
                        limiteFotosDespues.value = (4 - oListaFotosDespues.value.length);
                        estadoFotosDespues.value = limiteFotosDespues.value > 0 ? true : false;
                    }
                    
                    bCargando.value = false;
                }
            });
        }
        
    }
    catch (error) {
        console.error('Error al ListarPersonal:', error);
    } finally {
        bCargando.value = false;
    }
}

const ListarCitaFotos = async(id, tipo) => {
    try{
        const request = {
            CitaAtencionId: id
        };
        citaAtencionService.ListarCitasFotosService(request).then(
            response => {
                if(tipo == 1) {
                    oListaFotosAntes.value = response.Data.filter(fila => fila.TipoFotoId == tipo);
                    limiteFotosAntes.value = (4 - oListaFotosAntes.value.length);
                    estadoFotosAntes.value = limiteFotosAntes.value > 0 ? true : false;
                }
                else if(tipo == 2) {
                    oListaFotosDespues.value = response.Data.filter(fila => fila.TipoFotoId == tipo);
                    limiteFotosDespues.value = (4 - oListaFotosDespues.value.length);
                    estadoFotosDespues.value = limiteFotosDespues.value > 0 ? true : false;
                }
            }
        );
    }
    catch (error) {
        console.error('Error al ListarEstadoCita:', error);
    }
}

const ObtenerLimiteComputado = computed(() => {
    return limiteFotosAntes.value;
});

const EliminarImagen = (archivo, tipo) => {
    confirm.require({
        message: `¿Está seguro de eliminar la foto ${archivo.NombreOriginal}?`,
        header: 'Confirmación',
        icon: 'pi pi-info-circle',
        rejectLabel: 'No',
        acceptLabel: 'Eliminar',
        acceptClass: 'p-button-danger',
        accept: async () => {
            bCargando.value = true;
             try{
                const request = {
                    CitaFotoId: archivo.CitaFotosId,
                    UsuarioCreacion: usuarioStore.User.UsuarioId
                };

                const response = await citaAtencionService.EliminarCitaAtencionFotosService(request).then();
                if(response.Status == 200){
                    confirm.require({
                        group: 'headless',
                        header: 'Felicidades',
                        message: `Se eliminó la foto ${archivo.NombreOriginal} correctamente`,
                        accept: async() => {
                            const requestEliminar = {           
                                NombreArchivo: archivo.NombreImagen,
                                TipoArchivo: tipo 
                            };
                            
                            await archivosService.EliminarArchivosService(requestEliminar);
                            await ListarCitaFotos(citaId.value, tipo);
                            bCargando.value = false;
                        }
                    });                    
                }
            } catch (error) {
                bCargando.value = false;
                console.error('Error al EliminarCita', error);
            }
        }
    });
}

const VerImagenCompleta = (index) => {
    activarIndice.value = index;
    mostrarPersonalizado.value = true;
};

//#endregion
</script>


<style scoped>
.EsResaltante {
    background-color: #ceffef;
}
</style>