import { createStore } from 'vuex'

export default createStore({
  state: {
    datauser: null,
    SeleccionTipoProducto: null
  },
  getters: {
    isAuthenticated(state) {
      //return localStorage.getItem('datauser');
      return state.datauser;
    },
  },
  mutations: {
    login(state, data) {
      state.datauser = data;
      localStorage.setItem('datauser', data);
    },
    logout(state) {
      state.datauser = null;
      localStorage.removeItem('datauser');
      //state.SeleccionTipoProducto = null;
      //localStorage.removeItem('SeleccionTipoProducto');
    },    
    actualizarCaja(state, nuevaCaja) {
      if (state.datauser) {
        const data = JSON.parse(state.datauser);
        const nuevoData = { 
          ...data, 
          Caja: nuevaCaja 
        };

        state.datauser = JSON.stringify(nuevoData);        
        localStorage.setItem('datauser', state.datauser);
      }
    },
    initialiseStore(state) {
      if (localStorage.getItem('datauser')) {
        state.datauser = localStorage.getItem('datauser');
      }
      /*if (localStorage.getItem('SeleccionTipoProducto')) {
        state.SeleccionTipoProducto = localStorage.getItem('SeleccionTipoProducto');
      }*/
    }/*,
    SeleccionarTipoProducto(state, tipo){
      state.SeleccionTipoProducto = tipo;
      localStorage.setItem('SeleccionTipoProducto', tipo);
    }*/
  },
  actions: {
  },
  modules: {
  }
})


//EN EL COMPONENTE
/*const seleccionarTipo = (fila) => {
    store.commit('SeleccionarTipoProducto', JSON.stringify(fila));
    router.push('/portal/mantenedorproducto');
}
    
leer
const tipoProductoStore = JSON.parse(store.state.SeleccionTipoProducto);
*/