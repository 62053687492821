export default class PersonalService {
    async ListarPersonalService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/gestiontalento/personal', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }
}