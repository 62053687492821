<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <div v-if="bCargando" class="progress-spinner-custom">
                    <div class="spinner">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <h1>{{ (parametro ? 'Editar' : 'Nuevo') + ' ' + nombreTipoProducto }} </h1>
                    </template>

                    <template #end>
                        <Button label="Atrás" icon="pi pi-arrow-left" severity="secondary" @click="IrAtras"></Button>
                    </template>
                </Toolbar>
                <div class="flex flex-column h-500rem">
                    <div class="border-2 border-dashed surface-border border-round surface-ground justify-content-center align-items-center font-medium">
                        
                        <div class="p-fluid p-formgrid grid m-5">
                            <div class="field col-12 md:col-6" v-if="parametro">
                                <FloatLabel>
                                    <InputText id="txtProductoId" v-model="Producto.ProductoId" type="hidden" />
                                    <InputText id="txtCodigoProducto" v-model="Producto.CodigoProducto" autocomplete="off" readonly />
                                    <label for="lblCodigoProducto">Código Producto</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6" v-if="parametro">
                                <Checkbox id="chkEstado" v-model="Producto.Estado" :binary="true" />
                                <label for="lblEstado" class="ml-2 label-check"> Activo </label>
                            </div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <InputText  autocomplete="off" v-model="Producto.Nombre"
                                        :class="{ 'p-invalid' : nombreInvalido }" autofocus />
                                    <label for="lblPrimerNombre">Nombre</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6"></div>
                            <div class="field col-12 md:col-12">
                                <FloatLabel>
                                    <Textarea id="txtReferencia" v-model="Producto.Descripcion" rows="3" cols="30" />
                                    <label for="lblReferencia">Descripción</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6" v-if="TipoProducto.TienePrecioCompra">
                                <FloatLabel>
                                    <InputNumber autocomplete="off" v-model="Producto.PrecioCompra" 
                                        inputId="locale-us" locale="en-ES" :minFractionDigits="2" 
                                        :class="{ 'p-invalid' : precioCompraInvalido }" />                                    
                                    <label for="lblPrecioCompra">Precio Compra</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6" v-if="TipoProducto.TienePrecioVenta">
                                <FloatLabel>
                                    <InputNumber autocomplete="off" v-model="Producto.PrecioVenta" 
                                        inputId="locale-us" locale="en-ES" :minFractionDigits="2" 
                                        :class="{ 'p-invalid' : precioVentaInvalido }" />
                                    <label for="lblPrecioVenta">Precio Venta</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <Dropdown v-model="Producto.Categoria" :options="oListaCategoria" 
                                        optionLabel="Nombre" optionValue="CategoriaId" 
                                        placeholder="Categoría" class="w-full" 
                                        :class="{ 'p-invalid' : categoriaInvalido }" />
                                    <label for="lblCategoria">Categoria</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-4">
                                <FloatLabel>
                                    <Dropdown v-model="Producto.UnidadMedida" :options="oListaUnidadMedida" 
                                        optionLabel="Nombre" optionValue="UnidadMedidaId" 
                                        placeholder="Unidad Medida" class="w-full" 
                                        :class="{ 'p-invalid' : unidadMedidaInvalido }" @change="ObtenerUnidades()"/>
                                    <label for="lblUnidadMedida">Unidad Medida</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-2">
                                <FloatLabel>
                                    <InputText  autocomplete="off" v-model="Producto.Unidades" readonly />
                                    <label for="lblPrimerNombre">Unidades</label>
                                </FloatLabel>
                            </div>
                            <Divider layout="horizontal" v-if="TipoProducto.TieneDetalle" />
                            <div class="col-12" v-if="TipoProducto.TieneDetalle" >
                                <DataTable ref="dtProductoDetalle" 
                                :value="oListaProductoDetalle"
                                dataKey="ProductoDetalleId"
                                :paginator="true" 
                                :rows="10"
                                :rowsPerPageOptions="[10, 25, 50, 100]"
                                :loading="bCargando"
                                class="p-datatable-sm"
                                >
                                <template #header>
                                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                        <h5 class="m-0">Detalle Producto</h5>
                                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                                            <Button label="Agregar" icon="pi pi-plus" class="p-button-info mr-2" @click="AgregarDetalle()" />
                                        </span>
                                    </div>
                                </template>

                                <Column field="Nombre" header="Nombre" :sortable="true">
                                    <template #body="slotProps">
                                        <b>{{ slotProps.data.Nombre }}</b><br>
                                        {{ slotProps.data.Codigo }}
                                    </template>
                                </Column>
                                <Column field="Nombre" header="Descripcion" :sortable="true">
                                    <template #body="slotProps">
                                        {{ slotProps.data.Descripcion }}
                                    </template>
                                </Column>
                                <Column style="text-align: center;" headerStyle="min-width:12rem;">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-trash" class="p-button-rounded mt-2 p-button-danger"
                                                @click="QuitarDetalle(slotProps.data)" />
                                    </template>
                                </Column>

                            </DataTable>
                            </div>
                            
                        </div>

                    </div>

                    <div class="flex mt-4 justify-content-end">
                        <Button label="Guardar" icon="pi pi-save" iconPos="right" @click="Guardar()" :loading="bCargando" />
                    </div>

                </div>
            </div>
        </div>
    </div>

    <ConfirmDialog group="headless">
        <template #container="{ message, acceptCallback }">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                    <i class="pi pi-check text-5xl"></i>
                </div>
                <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
                <p class="mb-0">{{ message.message }}</p>
                <div class="flex align-items-center gap-2 mt-4">
                    <Button label="Aceptar" @click="acceptCallback"></Button>
                </div>
            </div>
        </template>
    </ConfirmDialog>

    <Dialog v-model:visible="mostrarDetalle" modal header="Agregar Detalle" :style="{ width: '50rem' }">
        
        <DataTable ref="dtProductos" 
            :value="oListaProductos"
            v-model:selection="oListaDetalleSeleccionados"
            dataKey="ProductoId"
            :paginator="true" 
            :rows="10"
            :rowsPerPageOptions="[10, 25, 50, 100]"
            :loading="bCargando"
            :filters="filtros"
            class="p-datatable-sm"
            >

            <template #header>
                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                    <span class="text-surface-500 dark:text-surface-400 block">Seleccione un producto para agregarlo como detalle.</span>
                    <span class="block mt-2 md:mt-0 p-input-icon-left">
                        <InputText v-model="filtros['global'].value" placeholder="Buscar..." />
                    </span>
                </div>
            </template>
            <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
            <Column field="Codigo" header="Código" :sortable="true"></Column>
            <Column field="Nombre" header="Nombre" :sortable="true">
                <template #body="slotProps">
                    {{ slotProps.data.Nombre }}
                </template>
            </Column>
        </DataTable>

        <div class="flex justify-end gap-2">
            <Button type="button" label="Cancelar" severity="secondary" @click="mostrarDetalle = false"></Button>
            <Button type="button" label="Aceptar" @click="GuardarDetalle()"></Button>
        </div>
    </Dialog>
</template>

<script setup>
import ProductoService from '@/service/ProductoService';
import CategoriaService from '@/service/CategoriaService';
import TipoProductoService from '@/service/TipoProductoService';
import UnidadMedidaService from '@/service/UnidadMedidaService';
import Crypto from '@/utilitarios/Crypto';
import { useToast } from 'primevue/usetoast';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useConfirm } from 'primevue/useconfirm';
import Utilitario from '@/utilitarios/Utilitario';
import { FilterMatchMode } from 'primevue/api';

//#region Variables
const route = useRoute();
const router = useRouter();
const toast = useToast();
const store = useStore();
const confirm = useConfirm();

const tipoProductoService = new TipoProductoService();
const categoriaService = new CategoriaService();
const unidadMedidaService = new UnidadMedidaService();
const productoService = new ProductoService();

const usuarioStore = JSON.parse(store.state.datauser);
const parametro = ref(null);
const bCargando = ref(false);
const TipoProducto = ref({});
const nombreTipoProducto = ref('');
const Producto = ref({
    ProductoId: 0
});
const oListaCategoria = ref([]);
const oListaUnidadMedida = ref([]);
const oListaProductoDetalle = ref([]);
const oListaProductos = ref([]);
const oListaDetalleSeleccionados = ref([]);

const nombreInvalido = ref(false);
const precioCompraInvalido = ref(false);
const precioVentaInvalido = ref(false);
const categoriaInvalido = ref(false);
const unidadMedidaInvalido = ref(false);
const mostrarDetalle = ref(false);
const filtros = ref({});
//#endregion

//#region Eventos
onMounted(() => {
    Inicializador();
});

onBeforeMount(() => {
    inicializarFiltros();
});
//#endregion

//#region Metodos
const Inicializador = async() => {
    const tipoId = Crypto.Desencriptar(route.params.tipoid);
    const id = Crypto.Desencriptar(route.params.id);
    parametro.value = id;
    bCargando.value = true;
    ObtenerTipoProducto(tipoId);
    ListarCategoria(tipoId);
    await ListarUnidadMedida();
    if(!Utilitario.StringIsNullOrEmpty(parametro.value)){
        await ObtenerProducto(id);
    }    
}

const inicializarFiltros = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const IrAtras = () => {
    router.push({ path: `/portal/mantenedorproducto/${encodeURIComponent(route.params.tipoid)}` });
}

const ObtenerTipoProducto = (id) => {
    tipoProductoService.ObtenerTipoProducto(id).then(
        response => {
            TipoProducto.value = response.Data;
            nombreTipoProducto.value = response.Data.Nombre;
        }
    );
}

const ListarCategoria = (tipoId) => {
    categoriaService.ListarCategoriaService(tipoId).then(
        response => {
            oListaCategoria.value = response.Data;
        }
    );
}

const ListarUnidadMedida = async() => {
    await unidadMedidaService.ListarUnidadMedidaService().then(
        response => {
            oListaUnidadMedida.value = response.Data;
            bCargando.value = false;
        }
    );
}

const ObtenerUnidades = async() => {
    if (Producto.value.UnidadMedida) {
        if(oListaUnidadMedida.value.length > 0){
            const unidadMedida = oListaUnidadMedida.value.find(um => um.UnidadMedidaId === Producto.value.UnidadMedida);
            Producto.value.Unidades = unidadMedida.Unidades;
        }
    } else {
        Producto.value.Unidades = 0;
    }
}

const ValidarProducto = () => {
    const estadoValidacion = ref(true);
    const { ...model } = Producto.value;
    const { ...modelTipo } = TipoProducto.value;

    if(!model?.Nombre) {
        nombreInvalido.value = true;
        estadoValidacion.value = false;
    }
    else {
        nombreInvalido.value = false;
    }
    
    if(modelTipo.TienePrecioCompra) {
        if(!model?.PrecioCompra) {
            precioCompraInvalido.value = true;
            estadoValidacion.value = false;
        }
        else {
            precioCompraInvalido.value = false;
        }
    }    

    if(modelTipo.TienePrecioVenta) {
        if(!model?.PrecioVenta) {
            precioVentaInvalido.value = true;
            estadoValidacion.value = false;
        }
        else {
            precioVentaInvalido.value = false;
        }
    }

    if(!model?.Categoria) {
        categoriaInvalido.value = true;
        estadoValidacion.value = false;
    }
    else {
        categoriaInvalido.value = false;
    }

    if(!model?.UnidadMedida) {
        unidadMedidaInvalido.value = true;
        estadoValidacion.value = false;
    }
    else {
        unidadMedidaInvalido.value = false;
    }

    return estadoValidacion.value;
}

const AgregarDetalle = () => {
    mostrarDetalle.value = true;
    oListaProductos.value = oListaProductos.value.filter(fila => !oListaProductoDetalle.value.find(detalle => detalle.ProductoId === fila.ProductoId));
}

const QuitarDetalle = (detalle) => {
    confirm.require({
        message: `¿Está seguro de quitar ${detalle.Nombre}?`,
        header: 'Confirmación',
        icon: 'pi pi-info-circle',
        rejectLabel: 'No',
        acceptLabel: 'Quitar',
        acceptClass: 'p-button-danger',
        accept: async () => {
            const index = oListaProductoDetalle.value.findIndex(elemento => elemento.ProductoId === detalle.ProductoId);
            if (index !== -1) {
                oListaProductoDetalle.value.splice(index, 1);
            }
        }
    });
}

const GuardarDetalle = () => {
    mostrarDetalle.value = false;
    let elementosAgregados = oListaDetalleSeleccionados.value.map(data => {
        return {
            ProductoId: data.ProductoId,
            Nombre: data.Nombre,
            Descripcion: data.Descripcion,
            Codigo: data.Codigo
        };
    });
    oListaProductoDetalle.value.push(...elementosAgregados);
    oListaDetalleSeleccionados.value = null;
}

const ObtenerProducto = async(id) => {
    bCargando.value = true;
    try{
        productoService.ObtenerProductoService(id).then(
            response => {
                const obtenerProducto = response.Data;
                Producto.value.ProductoId = obtenerProducto.ProductoId;
                Producto.value.CodigoProducto = obtenerProducto.Codigo;
                Producto.value.Estado = obtenerProducto.Estado;
                Producto.value.Nombre = obtenerProducto.Nombre;
                Producto.value.Descripcion = obtenerProducto.Descripcion;
                Producto.value.PrecioCompra = obtenerProducto.PrecioCompra;
                Producto.value.PrecioVenta = obtenerProducto.PrecioVenta;
                Producto.value.Categoria = obtenerProducto.Categoria.CategoriaId;
                Producto.value.UnidadMedida = obtenerProducto.UnidadMedida.UnidadMedidaId;
                oListaProductoDetalle.value = obtenerProducto.ListaProductoDetalle;
                ObtenerUnidades();
                if(TipoProducto.value.TieneDetalle) {
                    ListarProductosDetalle();
                }
                bCargando.value = false;
            }
        );
    } catch (error) {
        bCargando.value = false;
        console.error('Error al ObtenerProducto:', error);
    }
}

const ListarProductosDetalle = () => {
    const request = {
        TipoProducto: {
            TipoProductoId: 3
        } ,
        Establecimiento : {
            EstablecimientoId: usuarioStore.User.EstablecimientoId
        }
    };

    productoService.ListarProductosService(request).then(
        response => {
            const nuevaLista =  response.Data.filter(fila => !oListaProductoDetalle.value.find(detalle => detalle.ProductoId === fila.ProductoId));
            oListaProductos.value = nuevaLista;
        }
    );
}

const Guardar = () => {
    if(ValidarProducto()) {
        const tipoId = Crypto.Desencriptar(route.params.tipoid);
        const { ...model } = Producto.value;
        const request = {
            ...model,
            TipoProducto: {
                TipoProductoId: tipoId
            },
            ListaProductoDetalle: oListaProductoDetalle.value,
            Establecimiento: {
                EstablecimientoId: usuarioStore.User.EstablecimientoId
            },
            UsuarioCreacion: usuarioStore.User.UsuarioId
        }

        confirm.require({
            message: `¿Está seguro de guardar ${nombreTipoProducto.value}?`,
            header: 'Confirmación',
            icon: 'pi pi-info-circle',
            rejectLabel: 'No',
            acceptLabel: 'Si',
            accept: async () => {
                bCargando.value = true;
                
                try{
                    const response = await productoService.RegistrarProductoService(request);
                    if(response.Status == 201){
                        Producto.value.ProductoId = response.Data[0].ProductoId;
                        parametro.value = response.Data[0].ProductoId;
                        
                        confirm.require({
                            group: 'headless',
                            header: 'Felicidades',
                            message: `Se guardó el ${nombreTipoProducto.value} correctamente`,
                            accept: async() => {
                                bCargando.value = false;
                                router.push({ path: `/portal/mantenedorproducto/${encodeURIComponent(route.params.tipoid)}` });
                            }
                        });
                    }
                }
                catch (error) {
                    bCargando.value = false;
                    console.error(`Error al Guardar el ${nombreTipoProducto.value}:`, error);
                }
            }
        });
    }
    else {
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
    }
}
//#endregion
</script>
