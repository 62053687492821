export default class CajaService {
    async ObtenerUltimaCajaService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/caja/ultimacaja`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        });

        return await response.json();
    }
    
    async RegistrarAperturaCierreCajaService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/caja/cierreaperturacaja`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        });

        return await response.json();
    }

    async ObtenerCajaService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/caja/caja`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        });

        return await response.json();
    }
}