export default class EstablecimientoService {
    async ListarEstablecimientoService() {
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/mantenedor/establecimientos`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return await response.json();
    }
}