<script setup>
import { ref, onMounted } from "vue";
import AppMenuItem from "./AppMenuItem.vue";
import { useStore } from "vuex";

const store = useStore();

const model = ref([]);

const Fn_Childs = (idMenuParent, data) => {
  let item = data
    .filter((d) => d.MenuPadreId == idMenuParent)
    .map((d2) => {
      return {
        label: d2.Nombre,
        icon: d2.Icono ? d2.Icono : "",
        to: d2.Redireccion ? d2.Redireccion : "#",
        items: Fn_Childs(d2.MenuId, data),
      };
    });
  return item;
};

onMounted(() => {
  let dUser = JSON.parse(store.state.datauser);
  
  if(dUser){
    let menuArray = dUser.MenuOptions.filter((d) => d.Nivel == 0).map((d) => {
      return {
        label: d.Nombre,
        items: Fn_Childs(d.MenuId, dUser.MenuOptions),
      };
    });

    for (let i = 0; i < menuArray.length; i++) {
      if(menuArray[i].items.length > 0){
        for(let j = 0; j < menuArray[i].items.length; j++){
          if(menuArray[i].items[j].items.length > 0){
            for(let k = 0; k < menuArray[i].items[j].items.length; k++){
              if(menuArray[i].items[j].items[k].items.length){
                //empty
              }else{
                delete menuArray[i].items[j].items[k].items
              }
            }
          }else{
            delete menuArray[i].items[j].items
          }
        }
      }else{
        delete menuArray[i].items;
      }
    }

    model.value = menuArray;
  }

});
</script>

<template>
  <ul class="layout-menu">
    <template v-for="(item, i) in model" :key="item">
      <app-menu-item
        v-if="!item.separator"
        :item="item"
        :index="i"
      ></app-menu-item>
      <li v-if="item.separator" class="menu-separator"></li>
    </template>
    <!-- <li>
            <a href="https://www.primefaces.org/primeblocks-vue/#/" target="_blank">
                <img src="/layout/images/banner-primeblocks.png" alt="Prime Blocks" class="w-full mt-3" />
            </a>
        </li> -->
  </ul>
</template>

<style lang="scss" scoped></style>
