export default class FichaAtencionService {
    async RegistrarFichaAtencionService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/atencion/nuevaficha', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });
        
        return response.json();
    }

    async ObtenerFichaService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/atencion/ficha', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async ListarFichasPacienteService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/atencion/fichaspaciente', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async EliminarFichaAtencionService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/atencion/eliminarficha', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }
}