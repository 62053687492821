<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="NuevoProducto" />
                    </template>

                    <template #end>
                        <Button label="Atrás" icon="pi pi-arrow-left" severity="secondary" @click="IrAtras"></Button>
                    </template>
                </Toolbar>
            </div>
        </div>
    </div>
</template>


<script setup>
import { FilterMatchMode } from 'primevue/api';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

//#region Variables
const store = useStore();
const toast = useToast();
const confirm = useConfirm();

const usuarioStore = JSON.parse(store.state.datauser);

const bCargando = ref(false);
const filtros = ref({});
//#endregion

//#region Eventos
onMounted(() => {
    Inicializador();
});

onBeforeMount(() => {
    inicializarFiltros();
});
//#endregion


//#region Metodos
const Inicializador = () => {
    bCargando.value= true;
}

const inicializarFiltros = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};
//#endregion
</script>