export default class ReporteService {
    async ReportarPagos(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/reporte/pagos`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        });

        return await response.json();
    }

    async DescargarReportePDFService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/pdf/pdfreporte', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });
        
        return response.blob();
    }
}