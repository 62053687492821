export default class PacienteService {
    async ListarPacientesService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/mantenedor/pacientes', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async RegistrarPacienteService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/mantenedor/nuevopaciente', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async ActivarDesactivarPacienteService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/mantenedor/activardesactivarpaciente', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async ObtenerPacienteService(id) {
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/mantenedor/paciente/${id}`, {
            method: "GET"
        });
        
        return await response.json();
    }

    async ObtenerPacientePorPersonaService(id) {
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/mantenedor/pacientepersona/${id}`, {
            method: "GET"
        });
        
        return await response.json();
    }
}