export default class ArchivoService {
    async SubirArchivosService(formData) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/archivo/fotosantes', {
            method: 'POST',
            body: formData
        });

        return response.json();
    }

    async SubirArchivosDespuesService(formData) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/archivo/fotosdespues', {
            method: 'POST',
            body: formData
        });

        return response.json();
    }

    async EliminarArchivosService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/archivo/eliminarfoto', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }
}