<template>
    <div class="grid">
        <div class="col-12">
            <div class="card p-2">
                <Toolbar class="p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button icon="pi pi-user" severity="info" rounded outlined aria-label="User" />
                        <p><b class="ml-2">Ficha de Atención: </b> {{ nombrePaciente }} - {{ dniPaciente }}</p>
                    </template>

                    <template #end>
                        <Button label="Atrás" icon="pi pi-arrow-left" severity="secondary" @click="IrAtras"></Button>
                    </template>
                </Toolbar>
            </div>
            <div class="card" v-if="bCargando">
                <div class="progress-spinner-custom">
                    <div class="spinner">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 lg:col-6 xl:col-4" v-for="(item) in oListaCategoria" :key="item.CategoriaId" style="cursor: pointer;">
            <Card @click="ListarProductos(item)"  :class="{'seleccion-card': item.CategoriaId === categoriaSeleccionada}">
                <template #header>
                    <div class="pt-2" style="text-align: center;">
                        <i :class="item.Icono" class="text-cyan-500" style="font-size: 3rem;"></i>
                    </div>
                </template>
                <template #title>
                    <div class="text-center">{{ item.Nombre }}</div>
                </template>
                <template #subtitle>
                    <div class="text-center">{{ item.Descripcion }}</div>
                </template>
            </Card>
        </div>
        <div class="col-12" v-if="bMostrarProductos">
            <div class="card">
                <DataTable ref="dtProductos" 
                    :value="oListaProductos"
                    dataKey="ProductoId"
                    :paginator="true" 
                    :rows="5"
                    :rowsPerPageOptions="[5, 10, 25, 50, 100]"
                    :loading="bCargandoProductos"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >

                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Seleccione un paquete {{ nombreCategoria }}</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <InputText v-model="filtros['global'].value" placeholder="Buscar..." />
                            </span>
                        </div>
                    </template>
                    <Column style="text-align: center;" headerStyle="min-width:12rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-check" label="Seleccionar" severity="success" @click="SeleccionarPaquete(slotProps.data.ProductoId)" />        
                        </template>
                    </Column>
                    <Column field="Codigo" header="Código" :sortable="true"></Column>
                    <Column field="Nombre" header="Nombre" :sortable="true">
                        <template #body="slotProps">
                            <b>{{ slotProps.data.Nombre }}</b><br/>
                            {{ slotProps.data.Categoria.Nombre }}
                        </template>
                    </Column>                    
                    <Column field="PrecioVenta" header="Precio" :sortable="true">
                        <template #body="slotProps">
                            S/. {{ Utilitario.formatearDecimal(slotProps.data.PrecioVenta) }}
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script setup>
import CategoriaService from '@/service/CategoriaService';
import PacienteService from '@/service/PacienteService';
import ProductoService from '@/service/ProductoService';
import Crypto from '@/utilitarios/Crypto';
import Utilitario from '@/utilitarios/Utilitario';
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

//#region Variables
const router = useRouter();
const route = useRoute();
const store = useStore();

const usuarioStore = JSON.parse(store.state.datauser);
const categoriaService = new CategoriaService();
const pacienteService = new PacienteService();
const productoService = new ProductoService();

const oListaCategoria = ref(null);
const oListaProductos = ref(null);
const bCargando = ref(false);
const bCargandoProductos = ref(false);
const filtros = ref({});
const bMostrarProductos = ref(false);
const nombrePaciente = ref('');
const dniPaciente = ref('');
const tipoProductoId = ref(2);
const categoriaSeleccionada = ref(0);
const nombreCategoria = ref('');
//#endregion

//#region Eventos
onMounted(() => {
    inicializador();
});

onBeforeMount(() => {
    inicializarFiltros();
});
//#endregion

//#region Metodos
const inicializador = () => {
    ObtenerPaciente();
    ListarCategoria();
}

const inicializarFiltros = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const IrAtras = () => {
    router.push({ path: `/portal/nuevopaciente/${route.params.pacienteid}` });
}

const ListarCategoria = () => {
    bCargando.value = true;
    try{
        categoriaService.ListarCategoriaService(tipoProductoId.value).then(
            response => {
                const listaFiltrada = response.Data.filter(x => x.Estado);
                oListaCategoria.value = listaFiltrada;
                bCargando.value = false;
            }
        );
    } catch (error) {
        bCargando.value = false;
        console.error('Error al Listar Tipo Producto', error);
    }
}

const ObtenerPaciente = () => {
    try{
        const idPaciente = Crypto.Desencriptar(route.params.pacienteid);
        pacienteService.ObtenerPacienteService(idPaciente).then(
            response => {
                if(response.Data){
                    nombrePaciente.value = response.Data.Persona.NombreCompleto;
                    dniPaciente.value = response.Data.Persona.NumeroDocumento;
                }                
            }
        );
    }
    catch (error) {
        console.error('Error al ObtenerPaciente', error);
    }
}

const ListarProductos = (item) => {
    try{
        bMostrarProductos.value = true;
        bCargandoProductos.value = true;
        categoriaSeleccionada.value = item.CategoriaId;
        nombreCategoria.value = item.Nombre;
        const request = {
            TipoProducto: {
                TipoProductoId: item.TipoProducto.TipoProductoId
            } ,
            Establecimiento : {
                EstablecimientoId: usuarioStore.User.EstablecimientoId
            }
        };

        productoService.ListarProductosService(request).then(
            response => {
                oListaProductos.value = response.Data.filter(p => p.Categoria.CategoriaId == item.CategoriaId);
                bCargandoProductos.value = false;      
            }
        );
    }
    catch (error) {
        bCargandoProductos.value = false;
        console.error('Error al ListarProductos', error);
    }
}

const SeleccionarPaquete = (id) => {
    const idProducto = encodeURIComponent(Crypto.Encriptar(id));
    const idPaciente = encodeURIComponent(route.params.pacienteid);
    router.push({ path: `/portal/fichaatencion/${idPaciente}/${idProducto}` });
}
//#endregion
</script>
