<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="NuevoPaciente" />
                        <Button label="Actualizar" icon="pi pi-plus" class="p-button-success mr-2" @click="NuevoPaciente" />
                    </template>
                </Toolbar>
            </div>
        </div>
    </div>
</template>

<script setup>
</script>