<template>
  <!--<div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">-->
  <div class="surface-ground md:flex align-items-center justify-content-left min-h-screen min-w-screen overflow-hidden">
    <img src="/layout/images/fondo_login.jpg" alt="Hero Image" style="opacity: 0.1; width: 100%; position: fixed;" />
    <Toast />
    <div v-if="isLoadinga">
      <div class="progress-spinner-custom">
        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
      </div>
    </div>

    <div class="md:flex flex-column align-items-center justify-content-center" style="position: relative;">
      <div 
        style="
          padding: 0 0.3rem 0 0;
          background-image: linear-gradient(to left, #22C55E 3%, rgba(33, 150, 243, 0) 5%);
          
        "
      >
        <div class="w-full surface-card py-8 px-5 sm:px-8 h-screen">
          <div class="text-center mb-5">
            <img src="layout/images/logo-jades.png" alt="Logo Jades" class="mb-5 w-6rem flex-shrink-0"/> 
            <div class="text-900 text-3xl font-medium mb-3">
              Bienvenido a Jade's
            </div>
            <span class="text-600 font-medium"
              >v2.0</span
            >
          </div>

          <div>
            <label class="block text-900 text-xl font-medium mb-2"
              >Usuario</label
            >
            <InputText
              id="email"
              type="text"
              placeholder="Usuario"
              class="w-full md:w-30rem mb-5"
              style="padding: 1rem"
              v-model="email"
              @keyup.enter="login"
            />
            <label class="block text-900 font-medium text-xl mb-2"
              >Contraseña</label
            >
            <Password
              id="password"
              v-model="password"
              placeholder="Contraseña"
              class="w-full mb-3"
              inputClass="w-full"
              :inputStyle="{ padding: '1rem' }"
              :feedback="false" 
              toggleMask
              style="display: block;"
              hideIcon="pi pi-eye-slash"
              @keyup.enter="login"
            >
          </Password>
            <div
              class="flex align-items-center justify-content-between mb-5 gap-5"
            >
            
            </div>
            <Button
              label="Ingresar"
              class="w-full p-3 text-xl p-button-success"
              @click="login"
            ></Button>
            <hr>
            <a href="https://app.jadesesteticaintegral.pe/">Cambiar de Servidor</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <AppConfig simple /> -->
</template>
  
<script setup>
import { ref } from "vue";
import UsuarioService from "@/service/UsuarioService";
import { useToast } from "primevue/usetoast";
import { useRouter } from "vue-router";
import { useStore } from 'vuex'
const router = useRouter();
const toast = useToast();
const store = useStore()

const email = ref("");
const password = ref(null);
const isLoadinga = ref(false);
const response = ref(null);

const usuarioService = new UsuarioService();

const login = async () => {
  try {
    isLoadinga.value = true;
    console.log('Servidor 1');
    const request = {
      Login: email.value,
      Password: password.value
    };
    response.value = await usuarioService.loginService(request);

    if (response.value.Status !== 200) {
      isLoadinga.value = false;
      if (response.value.Message !== "Success" && response.value.Message !== undefined) {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: response.value.Message,
          life: 3000,
        });
      } else {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Error en inicio de sesión",
          life: 3000,
        });
      }

      return;
    }
    
    store.commit('login',JSON.stringify(response.value.Data))
    isLoadinga.value = false;
    router.push({ path: "/portal" });
  } catch (error) {
    console.log(error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: error,
      life: 3000,
    });
  } finally {
    isLoadinga.value = false;
  }
};
</script>
<style scoped>
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}

.progress-spinner-custom {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.progress-spinner-custom:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.53);
}

</style>