<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">

                <div v-if="bCargando" class="progress-spinner-custom">
                    <div class="spinner">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <h1>{{ oCaja.CajaId == 0 ? 'Aperturar' : 'Cerrar' }} Caja <Tag severity="success">{{ oCaja.CajaId == 0 ? 'Caja Cerrada' : 'Caja Aperturada'}}</Tag></h1>
                    </template>

                    <template #end>
                        <Button label="Ver Movimientos Diarios" icon="pi pi-money-bill" severity="secondary" @click="IrMovimientos"></Button>
                    </template>
                </Toolbar>

                <div class="flex flex-column h-500rem">
                    <div class="border-2 border-dashed surface-border border-round surface-ground justify-content-center align-items-center font-medium">
                        <div class="p-fluid p-formgrid grid m-5 mb-0 mt-5">
                            <div class="field col-12 md:col-4">
                                <FloatLabel>
                                    <Dropdown v-model="oCaja.EstablecimientoId" :options="oListaEstablecimientos" 
                                        optionLabel="NombreComercial" optionValue="EstablecimientoId" 
                                        placeholder="Establecimiento" class="w-full" disabled />
                                    <label for="lblEstablecimiento">Establecimiento</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-4">
                                <FloatLabel>
                                    <Calendar showIcon iconDisplay="input" v-model="oCaja.UltimaFecha"
                                        inputId="dtpFechaPago" dateFormat="dd/mm/yy" showTime hourFormat="12" disabled />
                                    <label for="lblFechaPago">Fecha Anterior</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-4">
                                <FloatLabel>
                                    <InputNumber autocomplete="off" v-model="oCaja.SaldoAnterior" 
                                        inputId="locale-us" locale="en-ES" :minFractionDigits="2" disabled />
                                    <label for="lblImporte">Saldo Anterior</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-12">
                                <FloatLabel>
                                    <Textarea id="txtComentarios" rows="3" cols="30" v-model="oCaja.ComentariosAnterior" disabled />
                                    <label for="lblComentarios">Comentario Anterior</label>
                                </FloatLabel>
                            </div>
                            <Divider layout="horizontal" class="mb-5" />
                            <div class="field col-12 md:col-4">
                                <FloatLabel>
                                    <Calendar showIcon iconDisplay="input" v-model="oCaja.Fecha" disabled
                                        inputId="dtpFechaPago" dateFormat="dd/mm/yy" showTime hourFormat="12" />
                                    <label for="lblImporte">Fecha</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-4">
                                <FloatLabel>
                                    <InputNumber autocomplete="off" v-model="oCaja.SaldoInicial" :disabled="oCaja.CajaId != 0"
                                        inputId="locale-us" locale="en-ES" :minFractionDigits="2" class="inputtext-total" />
                                    <label for="lblImporte">Saldo Inicial</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-4" v-if="oCaja.CajaId != 0">
                                <FloatLabel>
                                    <InputNumber autocomplete="off" v-model="oCaja.SaldoFinal" disabled
                                        inputId="locale-us" locale="en-ES" :minFractionDigits="2" class="inputtext-final" />
                                    <label for="lblImporte">Saldo Final</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-12">
                                <FloatLabel>
                                    <Textarea id="txtComentarios" rows="3" cols="30" v-model="oCaja.Comentarios" />
                                    <label for="lblComentarios">Comentario</label>
                                </FloatLabel>
                            </div>
                        </div>                        
                    </div>

                    
                    <div class="flex mt-4 justify-content-end">
                        <Button :label="oCaja.CajaId == 0 ? 'Registrar Apertura' : 'Registrar Cierre'" icon="pi pi-save" iconPos="right" @click="RegistrarAperturaCierreCaja()" :loading="bCargando" />
                    </div>

                </div>
            </div>
        </div>
    </div>

    <ConfirmDialog group="headless">
        <template #container="{ message, acceptCallback }">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                    <i class="pi pi-check text-5xl"></i>
                </div>
                <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
                <p class="mb-0">{{ message.message }}</p>
                <div class="flex align-items-center gap-2 mt-4">
                    <Button label="Aceptar" @click="acceptCallback"></Button>
                </div>
            </div>
        </template>
    </ConfirmDialog>

    <ConfirmDialog group="headlessvalida">
        <template #container="{ message, acceptCallback }">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div class="border-circle custom-bg-warning inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                    <i class="pi pi-exclamation-triangle text-5xl"></i>
                </div>
                <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
                <p class="mb-0">{{ message.message }}</p>
                <div class="flex align-items-center gap-2 mt-4">
                    <Button label="Aceptar" class="custom-bg-warning" @click="acceptCallback"></Button>
                </div>
            </div>
        </template>
    </ConfirmDialog>

</template>

<script setup>
import CajaService from '@/service/CajaService';
import EstablecimientoService from '@/service/EstablecimientoService';
import Utilitario from '@/utilitarios/Utilitario';
import { usePrimeVue } from 'primevue/config';
import { useConfirm } from 'primevue/useconfirm';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

//#region Variables
const router = useRouter();
const store = useStore();
const confirm = useConfirm();

const establecimientoService = new EstablecimientoService();
const cajaService = new CajaService();

const bCargando = ref(false);
const oCaja = ref({
    CajaId: 0,
    SaldoAnterior: 0,
    SaldoInicial: 0,
    SaldoFinal: 0,
    Fecha: new Date,
    Cierre: false
});

const usuarioStore = JSON.parse(store.state.datauser);
const oListaEstablecimientos = ref([]);
//#endregion

//#region Eventos
onMounted(() => {
     inicializador();
     CambiarEspanol();
});
//#endregion


//#region Metodos
const inicializador = async() => {
    bCargando.value = true;
    await ListarEstablecimientos();
    oCaja.value.EstablecimientoId = usuarioStore.Establecimiento.EstablecimientoId;
    await ObtenerUltimaCaja();
    await ObtenerCaja();
    bCargando.value = false;
}

const CambiarEspanol = () => {
    const primevue = usePrimeVue();
    primevue.config.locale.dayNames = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    primevue.config.locale.dayNamesShort = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
    primevue.config.locale.dayNamesMin = ["D", "L", "M", "X", "J", "V", "S"];
    primevue.config.locale.firstDayOfWeek = 1;
    primevue.config.locale.monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    primevue.config.locale.monthNamesShort = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    primevue.config.locale.today = "Hoy";
    primevue.config.locale.clear = "Limpiar";
    primevue.config.locale.dateFormat = "dd/mm/yy";
    primevue.config.locale.weekHeader = "Sem";
    primevue.config.locale.pending = "Pendiente";
}

const IrMovimientos = () => {
    router.push({ path: `/portal/movimientodiariocaja` });
}

const ListarEstablecimientos = async() => {
    const response = await establecimientoService.ListarEstablecimientoService().then();
    if(response.Status == 200){
        oListaEstablecimientos.value = response.Data;
    }
}

const ObtenerUltimaCaja = async() => {
    const request = {
        EstablecimientoId: oCaja.value.EstablecimientoId
    };
    
    const response = await cajaService.ObtenerUltimaCajaService(request).then();
    if(response.Status == 200){
        const obtenerCaja = response.Data;
        if(obtenerCaja != null && obtenerCaja != undefined){
            oCaja.value.UltimaFecha = new Date(obtenerCaja.Fecha);
            oCaja.value.SaldoAnterior = obtenerCaja.SaldoFinal;
            oCaja.value.SaldoInicial = obtenerCaja.SaldoFinal;
            oCaja.value.ComentariosAnterior = obtenerCaja.Comentarios;
        }
    }
}

const ObtenerCaja = async() => {
    const request = {
        EstablecimientoId: oCaja.value.EstablecimientoId
    };
    
    const response = await cajaService.ObtenerCajaService(request).then();
    if(response.Status == 200){
        const obtenerCaja = response.Data;
        
        if(obtenerCaja != null && obtenerCaja != undefined){
            oCaja.value.CajaId = obtenerCaja.CajaId;
            oCaja.value.Fecha = new Date(obtenerCaja.Fecha);
            oCaja.value.SaldoInicial = obtenerCaja.SaldoInicial;
            oCaja.value.SaldoFinal = obtenerCaja.SaldoFinal;
            oCaja.value.Comentarios = obtenerCaja.Comentarios;
            
            if(obtenerCaja.DiaDiferente) {
                confirm.require({
                    group: 'headlessvalida',
                    header: 'CAJA',
                    message: `Tiene aperturada la caja del día ${Utilitario.formatoFecha(oCaja.value.Fecha, 'dd/MM/yyyy')}. \nPor favor cerrar y abrir en la fecha actual.`,
                    accept: async() => {
                        console.log(`Tiene aperturada la caja del día ${Utilitario.formatoFecha(obtenerCaja.Fecha, 'dd/MM/yyyy')}`);
                    }
                });
            }
        }        
    }
}

const RegistrarAperturaCierreCaja = async() => {
    try{
        const request = {
            ...oCaja.value,
            UsuarioCreacion: usuarioStore.User.UsuarioId
        };

        let aperturaCierre = 0;
        let mensajePregunta = '';
        let mensajeConfirmacion = '';
        const fechaFormateada = Utilitario.formatoFecha(oCaja.value.Fecha, 'dd/MM/yyyy HH:mm:ss');
        if(oCaja.value.CajaId == 0) {
            mensajePregunta = `¿Está seguro de APERTURAR caja del día ${fechaFormateada}\n con un saldo inicial de S/.${Utilitario.formatearDecimal(oCaja.value.SaldoInicial)} ?`;
            mensajeConfirmacion = `Se aperturó la caja del día ${fechaFormateada}`;
            aperturaCierre = 1;
        }
        else {
            mensajePregunta = `¿Está seguro de CERRAR caja del día ${fechaFormateada} con un saldo final de S/.${Utilitario.formatearDecimal(oCaja.value.SaldoFinal)} ?`;
            mensajeConfirmacion = `Se cerró la caja del día ${fechaFormateada}`;
        }

        confirm.require({
            message: mensajePregunta,
            header: 'Confirmación',
            icon: 'pi pi-info-circle',
            rejectLabel: 'No',
            acceptLabel: 'Si',
            accept: async () => {
                bCargando.value = true;
                
                const response = await cajaService.RegistrarAperturaCierreCajaService(request).then();
                if(response.Status == 201){
                    const obtenerCaja = response.Data;
                    oCaja.value.CajaId = obtenerCaja.CajaId;
                    confirm.require({
                        group: 'headless',
                        header: 'Felicidades',
                        message: mensajeConfirmacion,
                        accept: async() => {
                            bCargando.value = false;
                            if(aperturaCierre == 1){
                                const nuevaCaja = {
                                    ...request,
                                    CajaId: obtenerCaja[0].CajaId
                                };
                                ActualizarCajaSesion(nuevaCaja);
                            }
                            else{
                                ActualizarCajaSesion(null);
                            }
                            router.push({ path: `/portal/movimientodiariocaja` });
                        }
                    });   
                }
            }
        });
    } catch (error) {
        console.error('Error al ObtenerFicha:', error);
        bCargando.value = false;
    }
}

 const ActualizarCajaSesion = async(nuevaCaja) => {
    if(nuevaCaja){
        const actualizaCaja = {
            CajaId: nuevaCaja.CajaId,
            Fecha: nuevaCaja.Fecha,
            SaldoInicial: nuevaCaja.SaldoInicial,
            SaldoFinal: nuevaCaja.SaldoFinal,
            Comentarios: nuevaCaja.Comentarios,
            Cierre: false,
            DiaDiferente: 0,
            Estado: true
        };

        store.commit('actualizarCaja', actualizaCaja);
    }
    else{
        store.commit('actualizarCaja', null);
    }
 }
//#endregion

</script>


<style>
.custom-bg-warning {
  background-color: #eed202;
  border: #eed202;
}
</style>